import React, { Component,useReducer } from 'react';
import Dommuseum from "./Dommuseum";
import Overlay from "./Overlay";
import Swipe from "./Swipe";

import './App.css';
import './Sidebar.css';
import logodommuseum from './grafiken/Logo_Dommuseu.svg'
import logolandesmuseum from './grafiken/Logo_Landesmuseum.svg'
import LogoZeitenwende from './grafiken/zeitenwende1400.svg'
import pfeillang from './grafiken/pfeillang.svg'

//import smoothscroll from 'smoothscroll-polyfill';

// kick off the polyfill!


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
                isAuthorized:true,
                browserSupported:true,
                 windowInnerWidth:0,
                 windowInnerHeight:0,
                 device:"mobile",
                 isStart:true,
                 isDommuseum:false,
                 isHashtagClicked:false,
                 isAnimationOverlayZweiOrte:false,
                 passwordInput:"",
                 password:"backtothefuture",
                 dommuseumScreen:null,
                 domain:null
                 
    };
  }

  componentDidMount() {
   // smoothscroll.polyfill();
    // detect support
    var el = document.createElement('div');
    var supports_grid = typeof el.style.grid === 'string';
    console.log("Browser Supported:"+supports_grid);
this.setState({browserSupported:supports_grid});

  //

   /* setTimeout(() => window.scrollTo(0,1), 100)*/
    this.updateWindowDimensions();
    window.addEventListener('resize', ()=>this.updateWindowDimensions());
    window.addEventListener('hashchange', ()=>this.handleNewHash(), false);
    var domain=window.location.href.split("/").slice(0, 3).join('/');
    
    var location = window.location.pathname.replace(/^#\/?|\/$/g, '').split('/');
    this.handleNewHash();


    //const objDiv = document.getElementById('ContainerStart');
      // objDiv.scrollTop = objDiv.scrollHeight;
     // objDiv.scrollTop = 150;
     // objDiv.scrollTop = 0;

    this.setState({domain:domain});
  }
  
  componentDidUpdate(){
    window.onpopstate  = (e) => {

      if(e.state)
      {
        var dommuseumScreen=null;
        switch(e.state.id){
          case "#Kampf": dommuseumScreen=0; break;
          case "#Liebe": dommuseumScreen=1; break;
          case "#Welt": dommuseumScreen=2; break;
          case "#Message": dommuseumScreen=3; break;
          case "#Info": dommuseumScreen=4; break;
        }
     
        if(dommuseumScreen != null){
          this.setState({isDommuseum:true,
            isStart:false,
            isHashtagClicked:true,
            isAnimationOverlayZweiOrte:false,
            dommuseumScreen:dommuseumScreen});
        }
      }
    }
    

  }
 
handleNewHash() {

  var location = window.location.pathname.replace(/^#\/?|\/$/g, '').split('/');
  if(location[1]==="Dommuseum"|| location[1]==="dommuseum")
  {
    if (location.length>2){ 
      var dommuseumScreen=null;
      if (location[2]==="Kampf" || location[2]==="kampf") dommuseumScreen=0;
      else if(location[2]==="Liebe" || location[2]==="liebe") dommuseumScreen=1;
      else if (location[2]==="Welt" || location[2]==="welt") dommuseumScreen=2;
      else if (location[2]==="Message" || location[2]==="message") dommuseumScreen=3;
      else if (location[2]==="Info" || location[2]==="info") dommuseumScreen=4;
    }
    this.setState({isDommuseum:true,
      isStart:false,
      isHashtagClicked:true,
      isAnimationOverlayZweiOrte:false,
      dommuseumScreen:dommuseumScreen});
  }
  
}


  componentWillUnmount() {
    window.removeEventListener('resize', ()=>this.updateWindowDimensions());
    window.removeEventListener('hashchange', ()=>this.handleNewHash(), false);
  }
  
  updateWindowDimensions() {
    let device;
      if(window.innerWidth >= 1024 && window.innerWidth>window.innerHeight ) device="desktop";
        else if(window.innerWidth >= 768 && window.innerHeight>=420) {
                      if(window.innerWidth > window.innerHeight) device="tabletLandscape";
                      else device="tablet";
            }
          else if(window.innerWidth >= 544) device="mobileLandscape";
            else if(window.innerWidth < 544) device="mobile";
    this.setState({ windowInnerWidth: window.innerWidth, windowInnerHeight: window.innerHeight,device:device });
  }

  handleSwipe(dir){
    switch (dir){
      case "UP": console.log("UP SWIPE APP.js");break;
      case "DOWN": console.log("DOWN SWIPE"); break;
      case "LEFT":console.log("LEFT SWIPE");
                  if(this.state.isHashtagClicked){
                    this.setState({ isStart:false,
                      isDommuseum:true,
                      isAnimationOverlayZweiOrte:false});
                  }break;
      case "RIGHT": console.log("RIGHT SWIPE");break;
    } 
  }

  handleDommuseumBtnClick(){

    if(this.state.browserSupported)
    {
      this.setState({ isStart:false,
        isDommuseum:true,
        isAnimationOverlayZweiOrte:false});
    } else window.open( "https://www.dommuseum-hildesheim.de/de/content/zeitenwende1400", "_self" )
  }

  handleLandesmuseumBtnClick(){
    if(this.state.browserSupported)
    {
      window.open( "https://www.landesmuseum-hannover.de/ausstellungen/zeitenwende-1400/", "_self" );
    //this.setState({ isStart:false,
    //  isDommuseum:true,
    //  isAnimationOverlayZweiOrte:false});
    } else window.open( "https://www.landesmuseum-hannover.de/ausstellungen/zeitenwende-1400/", "_self" );
  }


  handleHashtagBtnClick(){
    this.setState({ isHashtagClicked:true,
                    isAnimationOverlayZweiOrte:true})
  }


  handleChange(event){
    this.setState({passwordInput: event.target.value});
    event.preventDefault();

  }

  handleSubmit(){
    console.log("SUBMIT");
      if(this.state.password===this.state.passwordInput)
      {
        console.log("Password korrekt");
        this.setState({ isAuthorized:true})
      }else console.log("Password falsch");
  }

  render() {
    let {isStart,isAuthorized,isHashtagClicked,isAnimationOverlayZweiOrte}=this.state;

    let GridcontainerClassname;
    if(isStart) GridcontainerClassname="Show GridcontainerStart";
    else GridcontainerClassname="Hide GridcontainerStart";

    let HashtagClassname;
   if (isHashtagClicked)HashtagClassname="Btn HashtagOff HashtagStart";
   else HashtagClassname="Btn HashtagStart";

   let backgroundImage;
   if(this.state.device==="mobile")backgroundImage="mobil_start.jpg";
   else if(this.state.device==="tablet") backgroundImage="mobil_start.jpg";
   else if(this.state.device==="desktop") backgroundImage="zeitenwendeStart.jpg";
   else backgroundImage="mobilquer_start.jpg";

   if(!isAuthorized){
   return(

    <form >
        <label>
          Geheimnis:
          <input type="text" value={this.state.passwordInput} onChange={(event)=>this.handleChange(event)} />
        </label>
        <input type="submit" value="senden" onClick={(event)=>this.handleSubmit(event)}/>
      </form>

   )
  }
  else
  {

    return (
    <div className="App">
       {this.state.browserSupported?
        <div className={GridcontainerClassname} id="ContainerStart">  
            <img className="FullImage" src={"/images/"+backgroundImage} alt="Hintergrundbild: Start"></img> 

            <img className="LogoZeitenwendeStart"src={LogoZeitenwende} alt="Logo: Zeitenwende"></img> 
            <div className={HashtagClassname}><div className="HashtagTextStart" onClick={()=>this.handleHashtagBtnClick()}>#Start</div></div>
            <Overlay fullscreen={true} animationStatus={isAnimationOverlayZweiOrte} profile={"Zweiorte"}> {/** AnimateIn and Out */}

            <div className="ZweiOrteHeader"><h1>Eine Ausstellung – Zwei Orte</h1></div>
                <div className="ZweiOrteText">
                                              <p>Der Mensch war und ist zu allen Zeiten auf der Suche nach Glück, Erfolg, Macht, Spiritualität, gesellschaftlichem Zusammenhalt und Sicherheit. Damals wie heute verbinden Netzwerke. Unser Portal spielt mit der aktuellen Netzgewohnheit. Diese Zeitenwende von heute läßt die Kraft der Impulse für die Zeitenwende 1400 erahnen. Besuchen Sie unsere Museen und Ausstellungen, um mit den Objekten aus jener Zeit, den Gedanken, der Kreativität, dem Aufbruch, der Angst, der Gewißheit der Menschen dieser historische Epoche mit ihrer Wende zur Moderne nahe zu sein.</p>
                </div>
                <div className="InfoRowStart">
                <img className="DommuseumButton" src={logodommuseum} alt="Button: Dommnuseum" onClick={()=>this.handleDommuseumBtnClick()}></img>
                      <img className="LandesmuseumButton" src={logolandesmuseum} alt="Button: Landesmuseum" onClick={()=>this.handleLandesmuseumBtnClick()}></img>
                 </div>
                 <div className="InfoRowStart">
                <img className="PfeilButtonLinks" src={pfeillang} alt="Button: Dommnuseum" onClick={()=>this.handleDommuseumBtnClick()}></img>
                      <img className="PfeilButtonRechts" src={pfeillang} alt="Button: Landesmuseum" onClick={()=>this.handleLandesmuseumBtnClick()}></img>
                 </div>


            </Overlay>
    {/*   <Swipe handleSwipe={(dir)=>this.handleSwipe(dir)}/>
       */}
        </div>
       :
       <div className="NoSupport">

<img className="NoSupportFullImage" src={"/images/"+backgroundImage} alt="Hintergrundbild: Start"></img> 
<div className="NoSupportText">
Zeitenwende 1400 <br/><br/>
  Dieser Browser unterstützt nicht die notwendigen Technologien um die Inhalte des folgenden Webangebotes darzustellen.<br/>
  <div className="NoSupportLinks">
  Die aktuellen Versionen der Browser Mozilla Firefox, Microsoft Edge, Google Chrome und Apple Safari könne die Seite darstellen.<br/><br/><br/>

  In reduzierter Form können die Inhalte zur Ausstellung Zeitenwende 1400 auch auf den Seiten des Dommuseums Hildesheim oder des Landesmuseums Hannover abgerufen werden:
  </div>
<br/>
  <img className="NoSupportButton" src={logodommuseum} alt="Button: Dommnuseum" onClick={()=>this.handleDommuseumBtnClick()}></img> <br/>
  <img className="NoSupportButton" src={logolandesmuseum} alt="Button: Landesmuseum" onClick={()=>this.handleLandesmuseumBtnClick()}></img>

</div>
       </div> 

      }
     {this.state.isDommuseum? <Dommuseum device={this.state.device} showContent={this.state.isDommuseum} hashScreen={this.state.dommuseumScreen} domain={this.state.domain}/>  
        :null} 

      
    </div>
    );
  }
  }
}

export default App;
